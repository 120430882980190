import { Container, Image } from "./Header.style";
import headerImage from "./header.png"

const Header = () => {
  return (
    <Container>
      <Image src={headerImage} alt="" />
    </Container>
  );
};

export default Header;
