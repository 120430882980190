import styled from "@emotion/styled";


const Container = styled.div`
  bottom: 0;
  position: fixed;
`;

const UiBackground = styled.img`
    min-height: 40px;
    max-width: 100%;
`;

const SelectedCharacter = styled.img`
    min-height: 40px;
    width: 100%;
    position: absolute;
    bottom: 0;
    max-width: 20%;
    left: 12px;
`;

const CharacterNameContainer = styled.div`
    position: relative;
    width: 45%;
    top: 195px;
`;

const CharacterName = styled.div`
    top: 0;
    right: 0;
    z-index: 2;
    font-size: 26px;
    font-weight: bold;
    position: absolute;
    width: 220px;
    text-align: center;
`;


export {Container, CharacterName, CharacterNameContainer, UiBackground, SelectedCharacter};