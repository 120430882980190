import styled from "@emotion/styled";


const Container = styled.div`
  
`;

const Image = styled.img`
    min-height: 40px;
    max-width: 100%;
`;


export {Container, Image};