import { Container, Button, Image, NameTag } from "./Roster.style";
import characters from "../../characters"
import {createRef, useRef} from 'react';


const CharacterSelection = ({ onChange }) => {

  // let refs = useRef([React.createRef(), React.createRef()]);
  let refs = useRef(characters.map((e) => {
    return createRef(e);
  }));

  const handleChange = (character, currentCharacter) => {
    onChange(character);
    if (refs.current[currentCharacter].current) {
      refs.current[currentCharacter].current.play();
    }
  }

  return (
    <Container>

      {characters.map((e, i) => {
        return (
          <Button key={i} onClick={() => handleChange(characters[i], i)}>
            <Image src={e.imageUrl} alt="" />
            <NameTag>{e.name}</NameTag>
            {e.audioUrl && <audio src={e.audioUrl} ref={refs.current[i]}></audio>}
          </Button>
        );
      })}
    </Container>
  );
};

export default CharacterSelection;
