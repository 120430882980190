import styled from "@emotion/styled";
import { keyframes } from '@emotion/react'

const gradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4);
  z-index: 6;
`;

const Container = styled.button`
  left: 0;
  right: 0;
  width: 100%;
  top: 30%;
  z-index: 4;
  background: #333;
  animation: ${gradient} 2s ease infinite;
  background: linear-gradient(-45deg, #f44336, #fbc02d);
  border: 8px solid #fff;
  text-shadow: 0.75px 0.75px #000;
  color: #fff;
  font-weight: bold;
  border: 0;
  padding: 8px 0;
  font-size: 22px;
  position: absolute;
  top: 51.5%;
  transform: skew(-6deg);
  cursor: pointer;
`;

const Video = styled.video`
  width: 100%;
  max-width: 500px;
`;

const TextContainer = styled.div`
  background: #000;
  display: inline-block;
  padding: 16px 40px;
`;

export {Container, TextContainer, Overlay, Video};
