import { Container, Button, Header, Image, Paragraph } from "./WelcomeScreen.style";
import { Link } from "react-router-dom";

import letter from "./letter.jpg"
import smashTheme from "../../audio/main-theme.mp3";

var audio = new Audio(smashTheme)
audio.volume =  0.2;

const WelcomeScreen = () => {
    return (
    <Container>
        <Header>Welcome, Shimmy and friends.</Header>

        <Paragraph>While we may have not been able to get everyone in the same room for this special occaison, it doesn't mean that there wasn't an opportunity for an <b>"EVERYONE IS HERE"</b> moment...</Paragraph>
        <Button onClick={audio.play()}>
            <Link to={"/choose-your-character"}>
                <Image src={letter} alt="An envelope with a strange red seal" width={120}/>
            </Link>
            <Link to={"/choose-your-character"} style={{color: "#fff", marginTop: "24px", textDecoration: "none"}}>
                <b>Continue onward</b>
            </Link>

            <Paragraph>* It is highly recommended that you view this site on a screen bigger than your phone.</Paragraph>
        </Button>
    </Container>)
}

export default WelcomeScreen;
