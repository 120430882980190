import { Container, TextContainer, Video } from "./VideoBanner.style";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useState } from 'react';

const VideoBanner = ({selectedCharacter}) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <>
            <Container onClick={handleClickOpen}>
                <TextContainer>
                    Ready to go!
                </TextContainer>
            </Container>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>{selectedCharacter.name} has joined the battle!</DialogTitle>
                <Video width="100%" autoPlay>
                    <source src={selectedCharacter.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                </Video>
            </Dialog>
        </>
    );
};

export default VideoBanner;
