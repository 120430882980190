import './App.css';
import Header from "./components/Header/Header"
import Roster from "./components/Roster/Roster"
import WelcomeScreen from "./components/WelcomeScreen/WelcomeScreen"
import VideoBanner from "./components/VideoBanner/VideoBanner"
import SelectedCharacters from "./components/SelectedCharacters/SelectedCharacters"
import characters from "./characters"
import {useState} from 'react';
import styled from "@emotion/styled";
import createHistory from "history/createBrowserHistory";
import { Router, Route, Switch } from "react-router-dom";

const history = createHistory();

const Container = styled.div`
  height: 100vh;
  background: #000;
`;

function App() {

  const [selectedCharacter, setSelectedCharacter] = useState(characters);

  const onCharacterChange = (i) => {
    setSelectedCharacter(i);
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <WelcomeScreen />
        </Route>
        <Route exact path="/choose-your-character">
        <Container>
          <Header />
          <Roster onChange={onCharacterChange} />
          {selectedCharacter.videoUrl && <VideoBanner selectedCharacter={selectedCharacter} />}
          <SelectedCharacters selectedCharacter={selectedCharacter} />
        </Container>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
