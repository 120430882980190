import adamImageUrl from "./images/adam.png";
import adamAudioUrl from "./audio/adam.m4a";

import shimmyImageUrl from "./images/shimmy.png";
import shimmyAudioUrl from "./audio/shimmy.m4a";

import shawnImageUrl from "./images/shawn.png";
import shawnAudioUrl from "./audio/shawn.m4a";

import seanImageUrl from "./images/sean.png";
import seanAudioUrl from "./audio/sean.m4a";

import drunkSeanImageUrl from "./images/drunk-sean.png";
import drunkSeanAudioUrl from "./audio/drunk-sean.m4a";

import mattImageUrl from "./images/matt.png";
import mattAudioUrl from "./audio/matt.m4a";

import benImageUrl from "./images/ben.png";
import benAudioUrl from "./audio/ben.m4a";

import amandaImageUrl from "./images/amanda.png";
import amandaAudioUrl from "./audio/amanda.m4a";

import caitlinImageUrl from "./images/caitlin.png";
import caitlinAudioUrl from "./audio/caitlin.m4a";

import calieghImageUrl from "./images/caliegh.png";
import calieghAudioUrl from "./audio/caliegh.m4a";

import rayImageUrl from "./images/ray.png";
import rayAudioUrl from "./audio/ray.m4a";
import rayVideoUrl from "./videos/ray.mov";

import rossImageUrl from "./images/ross.png";
import rossAudioUrl from "./audio/ross.m4a";
import rossVideoUrl from "./videos/ross.mov";

import morganImageUrl from "./images/morgan.png";
import morganAudioUrl from "./audio/morgan.m4a";
import morganVideoUrl from "./videos/morgan.mov";

import haleyImageUrl from "./images/haley.png";
import haleyAudioUrl from "./audio/haley.m4a";

import zoeImageUrl from "./images/zoe.png";
import zoeAudioUrl from "./audio/zoe.m4a";

import dylanaImageUrl from "./images/dylana.png";
import dylanaAudioUrl from "./audio/dylana.m4a";
import dylanaVideoUrl from "./videos/dylana.webm";

import samImageUrl from "./images/sam.png";
import samAudioUrl from "./audio/sam.m4a";
import samVideoUrl from "./videos/sam.mov";

import rowanImageUrl from "./images/rowan.png";
import rowanAudioUrl from "./audio/rowan.m4a";
import rowanVideoUrl from "./videos/rowan.webm";

import loganImageUrl from "./images/logan.png";
import loganAudioUrl from "./audio/logan.m4a";

import mattBigfordImageUrl from "./images/matt-bigford.png";
import mattBigfordAudioUrl from "./audio/matt-bigford.m4a";
import mattBigfordVideoUrl from "./videos/matt-bigford.webm";

import cazzyImageUrl from "./images/cazzy.png";
import cazzyAudioUrl from "./audio/cazzy.m4a";

import brookImageUrl from "./images/brook.png";
import brookAudioUrl from "./audio/brook.m4a";
import brookVideoUrl from "./videos/brook.mov"

import celesteImageUrl from "./images/celeste.png";
import celesteAudioUrl from "./audio/celeste.m4a";
import celesteVideoUrl from "./videos/celeste.mov";

import zeroSuitAdamImageUrl from "./images/zero-suit-adam.png";
import zeroSuitAdamAudioUrl from "./audio/zero-suit-adam.m4a";

import smokieImageUrl from "./images/smokie.png";
import smokieAudioUrl from "./audio/smokie.m4a";

import youngShawnImageUrl from "./images/young-shawn.png";
import youngShawnAudioUrl from "./audio/young-shawn.m4a";

import colinImageUrl from "./images/colin.png";
import colinAudioUrl from "./audio/colin.m4a";
import colinVideoUrl from "./videos/colin.mov";

import jacksonImageUrl from "./images/jackson.png";
import jacksonAudioUrl from "./audio/jackson.m4a";

import oliverImageUrl from "./images/oliver.png";
import oliverAudioUrl from "./audio/oliver.m4a";

import kyleImageUrl from "./images/kyle.png";
import kyleAudioUrl from "./audio/kyle.m4a";
import kyleVideoUrl from "./videos/kyle.mp4";

import stephenImageUrl from "./images/stephen.png";
import stephenAudioUrl from "./audio/stephen.m4a";
import stephenVideoUrl from "./videos/stephen.mov";

import sloneImageUrl from "./images/slone.png";
import sloneAudioUrl from "./audio/slone.m4a";

import noahImageUrl from "./images/noah.png";
import noahAudioUrl from "./audio/noah.m4a";

import jaidinImageUrl from "./images/jaidin.png";
import jaidinAudioUrl from "./audio/jaidin.m4a";
import jaidinVideoUrl from "./videos/jaidin.mov";

import trevorImageUrl from "./images/trevor.png";
import trevorAudioUrl from "./audio/trevor.m4a";

import candyImageUrl from "./images/candy.png";
import candyAudioUrl from "./audio/candy.m4a";
import candyVideoUrl from "./videos/candy.mp4";

import margoImageUrl from "./images/margo.png";
import margoAudioUrl from "./audio/margo.m4a";
import margoVideoUrl from "./videos/margo.mov";

import chenoaImageUrl from "./images/chenoa.png";
import chenoaAudioUrl from "./audio/chenoa.m4a";
import chenoaVideoUrl from "./videos/chenoa.mov";

import tannerImageUrl from "./images/tanner.png";
import tannerAudioUrl from "./audio/tanner.m4a";
import tannerVideoUrl from "./videos/tanner.mp4";

import ladnerLarryImageUrl from "./images/ladner-larry.png";
import ladnerLarryAudioUrl from "./audio/ladner-larry.m4a";

import davieWongImageUrl from "./images/davie-wong.png";
import davieWongAudioUrl from "./audio/davie-wong.m4a";

import mattsSunfireImageUrl from "./images/matts-sunfire.png";
import mattsSunfireAudioUrl from "./audio/matts-sunfire.m4a";

import darkShimmyImageUrl from "./images/dark-shimmy.png";
import darkShimmyAudioUrl from "./audio/dark-shimmy.m4a";

import shimmysMomImageUrl from "./images/shimmys-mom.png";
import shimmysMomVideoUrl from "./videos/shimmys-mom.mov";
import shimmysMomAudioUrl from "./audio/shimmys-mom.m4a";

import richImageUrl from "./images/rich.png";
import richAudioUrl from "./audio/rich.m4a";

import daisyImageUrl from "./images/daisy.png";
import daisyAudioUrl from "./audio/daisy.m4a";
import daisyVideoUrl from "./videos/daisy.mov";

import lyndonImageUrl from "./images/lyndon.png";
import lyndonAudioUrl from "./audio/lyndon.m4a";

import gracynImageUrl from "./images/gracyn.png";
import gracynAudioUrl from "./audio/gracyn.m4a";


import kevinImageUrl from "./images/kevin.png";
import kevinAudioUrl from "./audio/kevin.m4a";
import kevinVideoUrl from "./videos/kevin.mov";

import brandonImageUrl from "./images/brandon.png";
import brandonAudioUrl from "./audio/brandon.m4a";
import brandonVideoUrl from "./videos/brandon.mp4";

import toonShawnImageUrl from "./images/toon-shawn.png";
import toonShawnAudioUrl from "./audio/toon-shawn.m4a";

import ericLawsImageUrl from "./images/eric-laws.png";
import ericLawsAudioUrl from "./audio/eric-laws.m4a";

import calebImage from "./images/caleb.png";
import calebAudioUrl from "./audio/caleb.m4a";

import paigeImageUrl from "./images/paige.png";
import paigeAudioUrl from "./audio/paige.m4a";

import mayaImageUrl from "./images/maya.png";
import mayaAudioUrl from "./audio/maya.m4a";

import mattsParentsImageUrl from "./images/matts-parents.png";
import mattsParentsAudioUrl from "./audio/matts-parents.m4a";

import henryImageUrl from "./images/henry.png";
import henryAudioUrl from "./audio/henry.m4a";

import taylorImageUrl from "./images/taylor.png";
import taylorAudioUrl from "./audio/taylor.m4a";

import robImageUrl from "./images/rob.png";
import robAudioUrl from "./audio/rob.m4a";

import stephanImageUrl from "./images/stephan.png";
import stephAudioUrl from "./audio/steph.m4a";
import stephVideoUrl from "./videos/steph.mov";

import caesarImageUrl from "./images/caesar.png";
import caesarAudioUrl from "./audio/caesar.m4a";
import caesarVideoUrl from "./videos/caesar.mov";

import opieImageUrl from "./images/opie.png";
import opieAudioUrl from "./audio/opie.m4a";

import kaiImageUrl from "./images/kai.png";
import kaiAudioUrl from "./audio/kai.m4a";

import mattsBeardImageUrl from "./images/matts-beard.png";
import mattsBeardAudioUrl from "./audio/matts-beard.m4a";

const characters = [{
        name: "Shimmy",
        imageUrl: shimmyImageUrl,
        audioUrl: shimmyAudioUrl
    },
    {
        name: "Adam",
        imageUrl: adamImageUrl,
        audioUrl: adamAudioUrl,
    },
    {
        name: "Matt",
        imageUrl: mattImageUrl,
        audioUrl: mattAudioUrl
    },
    {
        name: "Shawn",
        imageUrl: shawnImageUrl,
        audioUrl: shawnAudioUrl
    },
    {
        name: "Sean",
        imageUrl: seanImageUrl,
        audioUrl: seanAudioUrl
    },
    {
        name: "Drunk Sean",
        imageUrl: drunkSeanImageUrl,
        audioUrl: drunkSeanAudioUrl
    },
    {
        name: "Ben",
        imageUrl: benImageUrl,
        audioUrl: benAudioUrl,
    },
    {
        name: "Amanda",
        imageUrl: amandaImageUrl,
        audioUrl: amandaAudioUrl
    },
    {
        name: "Caitlin",
        imageUrl: caitlinImageUrl,
        audioUrl: caitlinAudioUrl
    },
    {
        name: "Caliegh",
        imageUrl: calieghImageUrl,
        audioUrl: calieghAudioUrl
    },
    {
        name: "Ray",
        imageUrl: rayImageUrl,
        videoUrl: rayVideoUrl,
        audioUrl: rayAudioUrl
    },
    {
        name: "Ross",
        imageUrl: rossImageUrl,
        audioUrl: rossAudioUrl,
        videoUrl: rossVideoUrl
    },
    {
        name: "Morgan",
        imageUrl: morganImageUrl,
        audioUrl: morganAudioUrl,
        videoUrl: morganVideoUrl
    },
    {
        name: "Haley",
        imageUrl: haleyImageUrl,
        audioUrl: haleyAudioUrl
    },
    {
        name: "Zoë",
        imageUrl: zoeImageUrl,
        audioUrl: zoeAudioUrl
    },
    {
        name: "Dylana",
        imageUrl: dylanaImageUrl,
        audioUrl: dylanaAudioUrl,
        videoUrl: dylanaVideoUrl
    },
    {
        name: "Sam",
        imageUrl: samImageUrl,
        audioUrl: samAudioUrl,
        videoUrl: samVideoUrl
    },
    {
        name: "Rowan",
        imageUrl: rowanImageUrl,
        audioUrl: rowanAudioUrl,
        videoUrl: rowanVideoUrl
    },
    {
        name: "Logan",
        imageUrl: loganImageUrl,
        audioUrl: loganAudioUrl
    },
    {
        name: "Matt Bigford",
        imageUrl: mattBigfordImageUrl,
        audioUrl: mattBigfordAudioUrl,
        videoUrl: mattBigfordVideoUrl,
    },
    {
        name: "Cazzy",
        imageUrl: cazzyImageUrl,
        audioUrl: cazzyAudioUrl,
    },
    {
        name: "Brook",
        imageUrl: brookImageUrl,
        audioUrl: brookAudioUrl,
        videoUrl: brookVideoUrl
    },
    {
        name: "Celeste",
        imageUrl: celesteImageUrl,
        audioUrl: celesteAudioUrl,
        videoUrl: celesteVideoUrl,
    },
    {
        name: "Zero Suit Adam",
        imageUrl: zeroSuitAdamImageUrl,
        audioUrl: zeroSuitAdamAudioUrl,
    },
    {
        name: "Smokie",
        imageUrl: smokieImageUrl,
        audioUrl: smokieAudioUrl
    },
    {
        name: "Young Shawn",
        imageUrl: youngShawnImageUrl,
        audioUrl: youngShawnAudioUrl
    },
    {
        name: "Colin",
        imageUrl: colinImageUrl,
        audioUrl: colinAudioUrl,
        videoUrl: colinVideoUrl,
    },
    {
        name: "Jackson",
        imageUrl: jacksonImageUrl,
        audioUrl: jacksonAudioUrl
    },
    {
        name: "Kyle",
        imageUrl: kyleImageUrl,
        audioUrl: kyleAudioUrl,
        videoUrl: kyleVideoUrl,
    },
    {
        name: "Oliver",
        imageUrl: oliverImageUrl,
        audioUrl: oliverAudioUrl
    },
    {
        name: "Stephen",
        imageUrl: stephenImageUrl,
        audioUrl: stephenAudioUrl,
        videoUrl: stephenVideoUrl
    },
    {
        name: "Jaidin",
        imageUrl: jaidinImageUrl,
        audioUrl: jaidinAudioUrl,
        videoUrl: jaidinVideoUrl,
    },
    {
        name: "Caleb",
        imageUrl: calebImage,
        audioUrl: calebAudioUrl,
    },
    {
        name: "Slone",
        imageUrl: sloneImageUrl,
        audioUrl: sloneAudioUrl
    },
    {
        name: "Noah",
        imageUrl: noahImageUrl,
        audioUrl: noahAudioUrl
    },
    {
        name: "Trevor",
        imageUrl: trevorImageUrl,
        audioUrl: trevorAudioUrl
    },
    {
        name: "Candy",
        imageUrl: candyImageUrl,
        audioUrl: candyAudioUrl,
        videoUrl: candyVideoUrl
    },
    {
        name: "Margo",
        imageUrl: margoImageUrl,
        audioUrl: margoAudioUrl,
        videoUrl: margoVideoUrl
    },
    {
        name: "Chenoa",
        imageUrl: chenoaImageUrl,
        audioUrl: chenoaAudioUrl,
        videoUrl: chenoaVideoUrl
    },
    {
        name: "Tanner",
        imageUrl: tannerImageUrl,
        audioUrl: tannerAudioUrl,
        videoUrl: tannerVideoUrl
    },
    {
        name: "Ladner Larry",
        imageUrl: ladnerLarryImageUrl,
        audioUrl: ladnerLarryAudioUrl
    },
    {
        name: "Davie Wong",
        imageUrl: davieWongImageUrl,
        audioUrl: davieWongAudioUrl
    },
    {
        name: "Matt's Sunfire",
        imageUrl: mattsSunfireImageUrl,
        audioUrl: mattsSunfireAudioUrl
    },
    {
        name: "Dark Shimmy",
        imageUrl: darkShimmyImageUrl,
        audioUrl: darkShimmyAudioUrl
    },
    {
        name: "Your Mom",
        imageUrl: shimmysMomImageUrl,
        videoUrl: shimmysMomVideoUrl,
        audioUrl: shimmysMomAudioUrl
    },
    {
        name: "Rich",
        imageUrl: richImageUrl,
        videoUrl: shimmysMomVideoUrl,
        audioUrl: richAudioUrl
    },
    {
        name: "Daisy",
        imageUrl: daisyImageUrl,
        videoUrl: daisyVideoUrl,
        audioUrl: daisyAudioUrl
    },
    {
        name: "Lyndon",
        imageUrl: lyndonImageUrl,
        lyndonUrl: lyndonAudioUrl
    },
    {
        name: "Gracyn",
        imageUrl: gracynImageUrl,
        audioUrl: gracynAudioUrl,
        videoUrl: morganVideoUrl
    },
    {
        name: "Kevin",
        imageUrl: kevinImageUrl,
        audioUrl: kevinAudioUrl,
        videoUrl: kevinVideoUrl
    },
    {
        name: "Brandon",
        imageUrl: brandonImageUrl,
        audioUrl: brandonAudioUrl,
        videoUrl: brandonVideoUrl
    },
    {
        name: "Toon Shawn",
        imageUrl: toonShawnImageUrl,
        audioUrl: toonShawnAudioUrl
    },
    {
        name: "Eric Laws",
        imageUrl: ericLawsImageUrl,
        audioUrl: ericLawsAudioUrl
    },

    {
        name: "Paige",
        imageUrl: paigeImageUrl,
        audioUrl: paigeAudioUrl,
        videoUrl: stephenVideoUrl
    },
    {
        name: "Maya",
        imageUrl: mayaImageUrl,
        audioUrl: mayaAudioUrl
    },
    {
        name: "Matt's Parents",
        imageUrl: mattsParentsImageUrl,
        audioUrl: mattsParentsAudioUrl
    },
    {
        name: "Henry",
        imageUrl: henryImageUrl,
        audioUrl: henryAudioUrl
    },
    {
        name: "Taylor",
        imageUrl: taylorImageUrl,
        audioUrl: taylorAudioUrl
    },
    {
        name: "ROB",
        imageUrl: robImageUrl,
        audioUrl: robAudioUrl
    },
    {
        name: "Steph",
        imageUrl: stephanImageUrl,
        audioUrl: stephAudioUrl,
        videoUrl: stephVideoUrl
    },
    {
        name: "Caesar",
        imageUrl: caesarImageUrl,
        audioUrl: caesarAudioUrl,
        videoUrl: caesarVideoUrl
    },
    {
        name: "Opie",
        imageUrl: opieImageUrl,
        audioUrl: opieAudioUrl
    },
    {
        name: "Kaie",
        imageUrl: kaiImageUrl,
        audioUrl: kaiAudioUrl
    },
    {
        name: "Matt's Beard",
        imageUrl: mattsBeardImageUrl,
        audioUrl: mattsBeardAudioUrl
    },
]

export default characters;