import styled from "@emotion/styled";
import { keyframes } from '@emotion/react'

const gradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: linear-gradient(-45deg, #e4e720, #23a6d5);
	background-size: 400% 400%;
	animation: ${gradient} 6s ease infinite;
  max-width: 1280px;
  margin: 16px auto auto;
`;

const Button = styled.button`
  padding: 32px;
  background: transparent;
  border: 1.25px solid #000;
  text-shadow: 0.75px 0.75px #000;
  font-size: 24px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
`;

const Image = styled.img`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
`;

const NameTag = styled.span`
  position: absolute;
  text-transform: uppercase;
  font-size: 10px;
  left: 0;
  right: 0;
  bottom: 2px;
`;

export {Container, Button, Image, NameTag};