import styled from "@emotion/styled";
import { keyframes } from '@emotion/react'

const gradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

const Container = styled.div`
  background: linear-gradient(-45deg, #000, #222);
  animation: ${gradient} 6s ease infinite;
  height: 100vh;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.h1`
    margin: 0;
    padding-top: 40px;
    font-size: 40px;
    max-width: 820px;
    margin: 80px auto 0;
    line-height: 1.1;
`;

const Paragraph = styled.p`
    max-width: 480px;
    line-height: 1.5;
    color: #fff;
    margin-top: 32px;
`;

const Button = styled.button`
    background: none;
    display: flex;
    flex-direction: column;
    border: 0;
    align-items: center;
`;

const Image = styled.img`
    max-width: 120px;
    margin-top: 32px;
`;

export {Container, Header, Paragraph, Image, Button};
