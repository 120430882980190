import { Container, UiBackground, SelectedCharacter, CharacterNameContainer, CharacterName } from "./SelectedCharacters.style";
import ui from "./ui.png"

const SelectedCharacters = ({selectedCharacter}) => {
  return (
    <Container>
      <SelectedCharacter src={selectedCharacter.imageUrl} />
      <CharacterNameContainer>
        <CharacterName>{selectedCharacter.name}</CharacterName>
      </CharacterNameContainer>
      <UiBackground src={ui} alt="" />
    </Container>
  );
};

export default SelectedCharacters;
